.score-date-container {
  margin-top: 1rem;
  background: $gray-100;
  border-radius: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .score-row {
    padding: .2rem 0;
    margin-right: .05rem;
  }
  .total-row {
    border-top: 1px solid $border-color;
  }
}