.table-header {
  text-transform: uppercase;
  font-weight: bold;
}
.table-statistics {
  .statistic {
    font-size: 1.75rem;
  }
  .col-md-3 {
    margin-bottom: 1rem;
  }
}
.MuiPaper-root {
  border-radius: 0.5rem;
  background-color: $gray-100;
  border: 1px solid rgb(230, 230, 230);
  border-top: 2px solid $purple-200;
  // box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  box-shadow: none !important;
}
.MuiTypography-root,
.MuiTableCell-root {
  font-family: 'Montserrat', 'Open-Sans' !important;
}
.MuiTableRow-root.MuiTableRow-hover:hover {
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
}
.MuiIcon-fontSizeSmall {
  font-size: 0.75rem !important;
}
.MuiInputBase-root {
  font-size: 0.85rem !important;
}
.MuiTableCell-root {
  border-color: $border-color !important;
}
.MuiTableCell-head {
  font-weight: bold !important;
  background-color: $gray-100 !important;
}
.user-group-table {
  .MuiTableCell-head {
    background-color: #FCFCFD !important;
    color: #333;
    font-weight: bold;
    border-radius: 0.25rem;
  }

  .MTableToolbar-searchField {
    width: 100%;
      border: 1px solid #CFCFCF;
      border-radius: 8px;
      padding: 0.5rem;
      font-size: 0.75rem;
      box-shadow: 0px 1px 3px 0px #00000040;
      padding-left: 30px;
      padding-right: 8px;
  }
  .MuiToolbar-root.MuiToolbar-regular.jss5.MuiToolbar-gutters {
	display: none;
  }
  .MuiToolbar-root.MuiToolbar-regular[class*="MTableToolbar-root"].MuiToolbar-gutters {
    display: none;
  }
  .search-input-container {
    width: 100%;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;

    .search-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #A7A7B5;
    }

    .search-input {
      width: 100%;
      border: 1px solid #CFCFCF;
      border-radius: 8px;
      padding: 0.5rem;
      font-size: 0.75rem;
      box-shadow: 0px 1px 3px 0px #00000040;
      padding-left: 30px;
      padding-right: 8px;
    }
  }
}
.MuiTableCell-footer {
  border-bottom: 0 !important;
}
.MuiTableRow-root:empty {
  display: none;
}
.MuiInput-underline:after {
  border-color: $input-border-color-focused !important;
}
.MuiInputBase-input, .MuiMenuItem-root {
  font-family: 'Montserrat' !important;
}
.MTableHeader-header-55, .MuiTableHead-root th {
  z-index: 0;
}
.over-quota span {
  color: $white;
  font-weight: bold;
  padding: .25rem;
  border-radius: 4px;
  background-color: $danger;
}
.teams-table {
  th {
    line-height: 1.15rem;
    font-size: 0.75rem;
  }
  td {
    font-size: 0.75rem !important;
  }
  // font-size: 0.75rem;
}
.leaderboard-row {
  // margin-top: 0.5rem;
  // margin-bottom: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid $border-color; 
}
.MuiMenuItem-root {
  font-size: 0.75rem !important;
}
.recipe-search-result {
  img {
    object-fit: cover;
    height: 3.375rem;
    width: 5rem;
    border-radius: 0.25rem;
  }
}