.topnavbar {
  background-color: transparent;
  background-image: none;
  border-bottom: none;
  height: 5.625rem;
  max-height: 5.625rem;
  z-index: 800;
  .nav-link {
    color: $base-color;
  }
}
.topnavbar .navbar-header .brand-logo {
  // padding-top: 0;
  padding-bottom: 0;
  img {
    max-height: 3.75rem;
  }
}
.navbar {
  flex-wrap: nowrap;
  .navbar-brand {
    font-size: 1rem;
  }
}
.topnavbar .navbar-nav > .nav-item > .nav-link,
.topnavbar .navbar-nav > .nav-item.show > .nav-link {
  color: $base-color;
  text-align: center;
}
.preauth {
  .topnavbar {
    .navbar-brand {
      width: 9.875rem;
      position: absolute;
      top: 21px;
      left: 0;
    }
    justify-content: center;
    .signup-progress-bar-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .arrow-back {
        position: absolute;
        left: -24px;
        top: 20px;
        cursor: pointer;
        em {
          font-size: 1rem;
        }
      }
      .step-number {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
      .progress-bar {
        width: 18rem;
        height: 0.5rem;
        background-color: #eaeaef;
        mix-blend-mode: normal;
        box-shadow: inset 0px 1px 3px #ccc;
        border-radius: 0.25rem;
        .progress {
          background: $purple-600;
          box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.35);
          border-radius: 4px;
          &.step-1 {
            width: 25%;
          }
          &.step-2 {
            width: 50%;
          }
          &.step-3 {
            width: 75%;
          }
          &.step-4,
          &.step-5 {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 47.99rem) {
  .sidebar-toggle {
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 5.625rem;
  }
  .sidebar-toggle > em {
    color: $base-color;
    font-size: 1.375rem;
  }
  .topnavbar .navbar-header {
    margin-left: 1.5rem;
  }
}
@media (min-width: 48rem) {
  .topnavbar .navbar-header {
    text-align: left;
  }
}
.topnavbar-wrapper {
  z-index: 999999;
}
