body.print {
  background: none;
  font-family: 'Open Sans', Arial, sans-serif;
}
.print-container {
  padding: 0 8px;
  .recipe-photo {
    max-width: 100%;
    // margin-right: 1rem;
    // height: fit-content;
    object-fit: contain;
    object-position: left top;
  }
  .sub-recipe-name {
    background-color: $gray-200;
    border-radius: 6px;
    padding: 6px;
    margin-bottom: 8px;
    h4 {
      margin: 0;
    }
  }
}
@media print {
  #app, body, html {
    height: auto !important;
  }
  .print-header {
    display: none;
  }
  .recipe-photo {
    // max-width: 25%;
    max-height: 225px;
    max-width: auto;
  }
}