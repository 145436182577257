// Variables
$menu-width: 40vw;
$transition-duration: 0.5s;
$background-color: #fff;
$box-shadow-color: rgba(0, 0, 0, 0.3);
$overlay-color: rgba(0, 0, 0, 0.5);
$button-color: #03b7e5;
$button-disabled-color: #1B201B1F;
$button-hover-color: #03476e;
$header-bg-color: #f8f9fa;
$border-color: #ddd;
$font-size-large: 1.5rem;

.no-scroll {
  overflow: hidden;
}

// Side Menu Styles
.side-menu {
  height: fit-content;
  position: fixed;
  top: 0;
  right: -$menu-width - 20;
  width: $menu-width;
  min-width: 400px;
  height: 100vh;
  background-color: $background-color;
  box-shadow: -2px 0 5px $box-shadow-color;
  transition: right $transition-duration ease;
  z-index: 100100 !important; 
  transform: translateZ(0);

  &.open {
    right: 0;
    display: flex;
    flex-direction: column;
  }

  .inline-form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .side-menu-header {
	  position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid $border-color;

    .close-menu-button {
      position: absolute;
      top: 0;
      left: -80px;
      background: none;
      border: none;
      font-size: 40px;
	    color: $header-bg-color;
      cursor: pointer;
    }

    @include media-breakpoint-down(sm) {
      .close-menu-button {
        display: hidden;
      }
    }
  }

  .side-menu-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto; 
    flex: 1;

    .side-menu-label {
      display: block;
      margin-bottom: 8px;
      font-weight: bold;
      }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  .side-menu-footer {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px;
    border-top: 1px solid $border-color;
    background-color: $background-color;
    box-shadow: -2px 0 5px $box-shadow-color;

    .close-side-menu-button {
      display: none !important;
    }

    @include media-breakpoint-down(sm) {
      .close-side-menu-button {
        display: block !important;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .side-menu {
    min-width: 100%;
    height: 90vh;
	right: -$menu-width - 70;
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  z-index: 1000;
}

.submit-menu-button {
  padding: 10px 20px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: $button-color;
  color: $background-color;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: $button-hover-color;
  }
}

.close-menu-button {
	padding: 10px 20px;
	margin-right: 10px;
	margin-left: 10px;
	background-color: $background-color;
	color: black;
	border: 0.5px solid $border-color;
	border-radius: 5px;
	cursor: pointer;

	&:hover {
	  background-color: $border-color;
	}
  }
  
