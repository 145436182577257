.planner {
  h5 {
    font-size: 1rem;
  }
  .macro-progress-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.33rem;
    .macro-name {
      width: 6rem;
      font-size: 0.75rem;
      text-transform: uppercase;
      margin-right: 1rem;
      &.green-thumb {
        color: $green-600;
        font-weight: bold;
      }
    }
    .macro-progress {
      background-color: $gray-200;
      width: 100%;
      height: 4px;
      .macro-progress-value {
        height: 4px;
        background-color: $green-400;
      }
    }
    .macro-values {
      margin-left: 1rem;
      white-space: nowrap;
      width: 8rem;
      font-size: 0.75rem;
      .green-thumb {
        width: 1.25rem;
        margin-left: 0.5rem;
        margin-top: -0.25rem;
      }
    }
  }
  .micronutrient-chip {
	font-size: 0.75rem;
	margin: 0.4rem;
	padding: 0.6rem;
	border-radius: 1rem;
	background-color: $gray-200;
	display: inline-flex;
	justify-content: space-evenly;
	.micro-name {
		font-weight: bold;
		margin-right: 0.5rem;
	}
	.micro-value {
		font-size: 0.75rem;
		font-weight: lighter;
	}
  }
  .react-datepicker-wrapper {
    width: auto;
  }
  
  .meal-card {
    cursor: pointer;
    .card-header {
      font-size: 0.75rem;
      text-transform: uppercase;
    }
    .card-body {
      padding-top: 0;
      margin-top: -0.25rem;
      &.expanded {
        border-bottom: 1px solid $border-color;
      }
      .macros {
        font-size: 0.75rem;
        margin-top: .5rem;
        .macro {
          display: flex;
          flex-direction: row;
          margin-right: 2rem;
          
          .macro-label {
            margin-right: 0.25rem;
          }
        }
      }
      .portion-container {
        width: 100%;
        .brand_name {
          font-size: 0.75rem;
        }
        .portion-qty {
          background-color: $gray-200;
          padding: 0.25rem;
          border-radius: 0.25rem;
          font-size: 0.75rem;
        }
        margin-top: 2rem;
        &.first {
          margin-top: 1rem;
        }
        .macros {
          margin-top: 0;
          .macro {
            width: 1.5rem;
          }
        }
      }
      
    }
  }
  .workout-container {
    display: flex;
    flex-direction: row;
    margin: 1rem 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    em {
      font-size: 1.25rem;
      margin-right: 0.5rem;
      color: $gray-600;
      &.fa-check-circle {
        color: $blue-300;
      }
    }
  }
}