.Toastify__toast {
  min-height: 3rem !important;
  height: 4rem;
  font-size: 0.85rem;
  font-family: Montserrat, 'Open Sans'
}
.Toastify__toast--success {
  background-color: $calm-color;
}
.Toastify__toast--error {
  background-color: $assertive-color;
}
.Toastify__toast--warning {
  background-color: $warning-color;
}
.Toastify__toast--info {
  background-color: $blue-200 !important;
  color: $base-color !important;
}
