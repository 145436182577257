.str-chat, .str-chat__message-text {
  font-family: 'Montserrat', 'Open Sans' !important;
  --str-chat__font-family: Montserrat, 'Open Sans' !important;
  --str-chat__message-send-color: #10c9ff;
  --str-chat__own-message-bubble-background-color: #ace3ff;
  --str-chat__message-replies-count-color: #007fae;
  --str-chat__message-status-color: #007fae;
}

.str-chat__channel {
  // width: 100%;
  max-height: 600px;
  div, li, p, button, input {
    font-family: Montserrat, 'Open Sans' !important;
  }
}
.str-chat__channel-preview-messenger--name span {
  font-family: Montserrat, 'Open Sans' !important;
}
.str-chat__thread {
  width: 45%;
}
.str-chat__avatar-fallback {
  background-color: $blue-300 !important;
}
.all-chats-container {
  .str-chat__channel-list {
    width: 30% !important;
    // width: 300px;
    max-height: 800px !important;
  }
  .str-chat__channel {
    width: 70% !important;
    // width: 100%;
    max-height: 800px !important;
  }
  .str-chat__channel-preview .str-chat__channel-preview-end {
    max-width: 300px;
  }
  div, li, p, button, input {
    font-family: Montserrat, 'Open Sans' !important;
  }
  .str-chat__channel-list-messenger-react__main {
    max-height: 100%;
  }
  .str-chat__header-livestream-left--members {
    display: none;
  }
}
.str-chat__message-simple-name {
  display: none;
}
.section-container:has(div.str-chat__modal--open) {
  z-index: 10000 !important;
}
.str-chat__message-input .str-chat__message-textarea-container .str-chat__message-textarea {
  font-family: Montserrat, 'Open Sans' !important;
}