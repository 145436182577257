.subscription-container {
  border: 1px solid $border-color;
  padding: 1.5rem 1rem;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: 0.75rem;
  background-color: #fff;
  position: relative;
  h3 {
    font-size: 1.875rem;
    font-weight: bold;
  }
  &.signup {
    cursor: auto;
  }
  &.featured {
    border: 6px solid $green-400;
    margin-top: 0.667rem;
    background: linear-gradient(167.92deg, #2a2962 46.34%, #000b33 122.85%), #005775;
    color: #fff;
    .card-caption {
      color: #fff;
      opacity: 0.7;
    }
    .border-bottom {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    }
    .featured-callout {
      background-color: $green-400;
      width: 144px;
      text-align: center;
      color: #002130;
      font-size: 0.875rem;
      border-radius: 0.25rem;
      position: absolute;
      left: 50%;
      margin-left: -72px;
      top: -17px;
      height: 30px;
      line-height: 30px;
      font-weight: 600;
    }
  }
  .description-container {
    height: 3rem;
  }
  .price {
    font-size: 1.25rem;
    font-weight: bold;
  }
}
.features-list {
  list-style: none;
}
.cta-container {
  font-size: 1.3125rem;
}
