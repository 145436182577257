.content-wrapper {
  border-top: none;
}
.preauth {
  .signup-form-wrapper {
    width: 90%;
  }
  .plan-selection-header {
    img {
      margin-right: 276px;
      margin-bottom: 10px;
    }

    .btn-group-toggle {
      width: 19rem;
      height: 2.5rem;
      background-color: $white;
      border-radius: 300px;
      margin-bottom: 0.4rem;
      .savings-badge {
        background-color: $green-400;
        position: absolute;
        width: 60px;
        height: 60px;
        color: $blue-800;
        border-radius: 40px;
        z-index: 1;
        right: -30px;
        top: -30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .save-label {
          font-size: 0.75rem;
        }
        .save-value {
          font-size: 1.25rem;
          font-weight: bold;
          margin-top: -0.35rem;
        }
      }
      .btn {
        font-size: 0.875rem;
        line-height: 1.5rem;
        background-color: $white;
        border-radius: 300px;
        border: 2px solid $white;
        color: $gray-600;
        box-shadow: none;
        &:hover {
          z-index: 0;
        }
        &.active {
          background-color: $purple-900;
          border: 2px solid $white;
          box-sizing: border-box;
          color: #000;
          transition: all 0.2s ease-out;
          box-shadow: none;
          color: $white;
        }
      }
    }
  }
  .signup-callout-container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    // max-width: 32rem;
    max-width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2.5rem;
    .signup-callout-image {
      width: 100%;
      img {
        width: 100%;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 12px;
      }
    }
  }
  .signup-steps {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    .signup-step {
      margin-top: 1.5rem;
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.5rem;
      &.benefit {
        font-size: 1.25rem;
        line-height: 1.25rem;
        margin-top: 1rem;
      }
      .signup-step-number {
        font-size: 0.875rem;
        width: 2rem;
        height: 2rem;
        min-width: 2rem;
        max-height: 2rem;
        background-color: $purple-200;
        border-radius: 1rem;
        text-align: center;
        line-height: 2rem;
      }
      .signup-step-check em {
        color: $green-400;
      }
    }
  }
}
.signup-complete-container {
  font-size: 1rem;
  .row {
    margin: 0.75rem 0;
    .col {
      padding: 0;
    }
    &.border-bottom {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }
}
.only-on-mobile {
  display: none;
}
@media (min-width: 48rem) {
  .section-container {
    margin-top: -2.5rem;
    background-color: transparent !important;
    z-index: 1050 !important; // note: need to set this exactly to appear on top of header
    .content-wrapper {
      padding-top: 0 !important;
    }
  }
  .pre-auth {
    .section-container {
      margin-top: 0;
    }
  }
}
@media (max-width: 47.99rem) {
  .preauth {
    .navbar-brand {
      display: none;
    }
    .signup-form-wrapper {
      width: 100%;
    }
    .section-container {
      background-color: transparent !important;
    }
    .plan-selection-header {
      img {
        margin-right: 280px;
        margin-bottom: 10px;
      }
    }
  }
  .btn-search-with-reset.ml-3 {
    margin-left: 0.25rem !important;
  }
  .hidden-on-mobile {
    display: none;
  }
  .only-on-mobile {
    display: block;
  }
}
.air-mail-border {
  // background-image: repeating-linear-gradient(135deg, #F29B91 0px, #F09290 30px, transparent 30px, transparent 50px, #83B3DB 50px, #84ADCB 80px, transparent 80px, transparent 100px);
  border: 3px solid $purple-400;
  padding: 8px;
  border-radius: 1rem;
  .air-mail-container {
    background-color: #fff;
    padding: 8px;
  }
}
.challenge-detail-container {
  label {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }
}
.custom-link-icon {
  width: 1.5rem;
  height: 1.5rem;
}