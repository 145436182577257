html {
  scroll-behavior: smooth;
}
#app {
  max-width: 80rem;
  margin: 0 auto;
}
body.preauth {
  background-color: #fff;
  #app {
    max-width: 100%;
    .background-container {
      background-color: $purple-100;
      height: 40rem;
      overflow: visible;
      .background-wave {
        position: absolute;
        left: 0;
        top: 40rem;
        width: 100%;
        z-index: -1;
        img {
          width: 100%;
        }
      }
    }
    .wrapper {
      max-width: 80rem;
      margin: 0 auto;
    }
  }
}
.relative {
  position: relative;
}
.max-height {
  max-height: 100%;
}
.border-top {
  border-top: 1px solid $border-color;
}
.border-bottom {
  border-bottom: 1px solid $border-color;
}
.width-100-percent {
  width: 100%;
}
.display-none {
  display: none;
}
body,
.wrapper .section-container,
.wrapper .aside-container,
.sidebar,
.sidebar-subnav,
.header-user-menu button.btn-secondary {
  // background-color: #fff;
  background-color: $purple-100;
  // background-color: $gray-100 !important;
}
.section-container {
  z-index: 0 !important;
  margin-left: 0 !important;
  flex-basis: 66.25rem;
}
.w-65 {
  width: 65% !important;
}
.w-75 {
  width: 75% !important;
}
.pre-auth.wrapper {
  .section-container {
    margin-top: 2rem;
  }
}
.no-flex-on-mobile {
  display: flex;
  flex-direction: row;
}
@media (max-width: 47.99rem) {
  .wrapper .aside-container {
    left: 0;
    top: auto;
    bottom: auto;
    .aside-inner {
      padding-top: 1rem;
    }
  }
  .content-wrapper {
    // width: 95%;
  }
  .section-container {
    width: 100%;
  }
  .no-flex-on-mobile {
    display: block;
    div {
      width: 100%;
    }
    .no-margin-mobile {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}
body.preauth {
  border-top: 6px solid $purple-600;
}
.main-content-container {
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 12px;
  padding: 1.5rem 2rem;
}
.width-2-rem {
  width: 2rem;
}
.width-3-rem {
  width: 3rem;
}
.wrapper {
  overflow-x: visible !important;
}
.min-width-800 {
  min-width: 800px;
}
.overflow-x-scroll {
  overflow-x: scroll
}