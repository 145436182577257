#edit-img {
  display: none;
}
.ReactImagePickerEditor .place-image .image-holder-loaded img {
  // background-color: red;
}
.image-holder-loaded img {
  max-width: 280px !important;
  max-height: 64px !important;
  border-radius: 0 !important;
}
.image-holder, .image-holder-loaded {
  border-radius: 0 !important;
  border-width: 1px !important;
  border: 1px solid $border-color !important;
}
.image-holder-loaded {
  // background-color: red;
}
.logo-image-container {
  width: 375px;
  height: 154px;
  border: 1px solid $border-color;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
  img {
    max-width: 280px !important;
    max-height: 64px !important;
    border-radius: 0 !important;
  }
}
.cobrand-preview-container {
  width: 437px;
  height: 886px;
  // width: 375px;
  // height: 1120px;
  position: relative;
  .iphone-outline {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .inner {
    width: 385px;
    height: 815px;
    position: absolute;
    left: 26px;
    top: 26px;
    background-size: cover;
    z-index: 1;
    border-radius: 10px;
  }
  .logo-image-container {
    position: absolute;
    top: 267px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    width: 385px;
    height: 158px;
  }
  .powered-by-macrostax {
    font-size: 10.5px;
    color: $gray-300;
    position: absolute;
    top: 128px;
    left: 127px;
  }
  .bug {
    position: absolute;
    top: 50px;
    left: 20px;
    width: 24px;
    height: 24px;
    background-size: cover;
  }
  .team-name {
    position: absolute;
    left: 50px;
    top: 50px;
    color: #fff;
    filter: drop-shadow(1px 1px 1px #000);
  }
  .plus-button {
    width: 68px;
    height: 68px;
    background-size: cover;
    position: absolute;
    top: 231px;
    left: 158px;
    z-index: 3;
  }
}