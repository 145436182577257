// html {
//   overflow: scroll;
//   overflow-x: hidden;
//   -ms-overflow-style: none;
//   scrollbar-width: none;
// }
// ::-webkit-scrollbar {
//   width: 0;
//   background: transparent;
// }
.wrapper .aside-container {
  // bottom: auto;
  flex-basis: 13.75rem;
  position: relative;
}
.wrapper .aside-container .aside-inner {
  // padding-top: 7.5rem;
  padding-top: 2rem;
}
.sidebar:after {
  background: none;
}
.sidebar-nav > li > a,
.sidebar-nav > li > button {
  // padding: 1.25rem 1.5rem;
  padding: 12px 24px;
  display: flex;
  white-space: inherit;
}
.sidebar-subnav > li > a {
  padding: 0.5rem 1.5rem;
  font-size: 0.75rem;
}
.sidebar-nav > li.active,
.sidebar-nav > li.active > a,
.sidebar-nav > li.active > .nav-item,
.sidebar-nav > li.active .sidebar-nav,
.sidebar-nav > li.open,
.sidebar-nav > li.open > a,
.sidebar-nav > li.open > .nav-item,
.sidebar-nav > li.open .sidebar-nav {
  background: none;
}
.sidebar-nav > li.active,
.sidebar-nav > li.active > a,
.sidebar-nav > li.active > .nav-item {
  font-weight: bold;
}
.sidebar {
  .btn {
    width: 90%;
    margin-bottom: 1.125rem;
  }
}
.sidebar-nav > li > a > em,
.sidebar-nav > li > button > em,
.sidebar-nav > li > .nav-item > em,
.sidebar-nav > li > .MuiSvgIcon-root {
  font-size: 1.25rem;
  min-width: 2.25rem;
  text-align: left;
}
.sidebar-nav > li > a,
.sidebar-nav > li > span,
.sidebar-nav > li > button {
  color: $base-color;
  &:hover {
    text-decoration: none;
    color: $blue-300;
  }
}
.nav-item-standalone span {
  color: $base-color;
}
.nav-item:hover span {
  text-decoration: none;
  color: $blue-300;
}
@media (max-width: 47.99rem) {
  .wrapper .aside-container {
    position: absolute;
    .aside-inner {
      padding-top: 7.5rem;
    }
  }
}
.svg-icon {
  width: 1.8em;
  height: 1.25rem;
  display: inline-block;
}
.money-sack-light {
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M201.5 144h109l5.3 3.4C370.6 182.5 480 268.3 480 416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64c0-147.7 109.4-233.5 164.3-268.6l5.3-3.4zm98.4-32H212.1l-6.7-9.8L156.9 32H355.1l-48.4 70.2-6.7 9.8zM152.6 138.5C91.2 183.7 0 273.4 0 416c0 53 43 96 96 96H416c53 0 96-43 96-96c0-142.6-91.2-232.3-152.6-277.5c-9.7-7.1-18.6-13.2-26.4-18.1L351.2 94l38.9-56.4C401 21.7 389.6 0 370.3 0H141.7C122.4 0 111 21.7 121.9 37.6L160.8 94 179 120.4c-7.7 4.9-16.7 11-26.4 18.1zM272 216c0-8.8-7.2-16-16-16s-16 7.2-16 16v17.3c-8.3 1.5-16.7 4.3-24.1 8.7c-13 7.7-23.9 21.1-23.8 40.5c.1 18.4 10.8 30.1 22.7 37.3c10.5 6.3 23.8 10.4 34.8 13.7l0 0 1.6 .5c12.5 3.8 22.2 6.9 29 11.2c5.9 3.7 7.7 7 7.7 11.6c.1 6.6-2.7 10.8-7.8 14c-5.8 3.6-14.5 5.6-23.7 5.3c-11.8-.4-22.7-4.1-36.3-8.7l0 0 0 0 0 0c-2.3-.8-4.7-1.6-7.1-2.4c-8.4-2.8-17.4 1.7-20.2 10.1s1.7 17.4 10.1 20.2c2 .7 4.1 1.4 6.2 2.1l0 0c9.2 3.2 19.7 6.8 30.9 8.9V424c0 8.8 7.2 16 16 16s16-7.2 16-16V406.9c8.7-1.4 17.4-4.3 25.1-9.1c13.3-8.3 23.2-22.2 22.9-41.6c-.2-18.5-10.6-30.6-22.7-38.2c-11-6.9-25-11.2-36.3-14.6l0 0 0 0-.5-.1c-12.6-3.8-22.3-6.8-29.2-10.9c-6-3.6-7.2-6.4-7.3-10.1c0-5.4 2.4-9.4 8.1-12.8c6.2-3.7 15.1-5.7 23.6-5.5c10.1 .2 21 2.3 32.1 5.3c8.5 2.3 17.3-2.8 19.6-11.3s-2.8-17.3-11.3-19.6c-7.5-2-15.6-3.9-24.1-5.1V216z'/></svg>");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.feedback {
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M32 240c0-91.4 93.8-176 224-176s224 84.6 224 176s-93.8 176-224 176c-32 0-63-5.9-93.5-16.2c-9.9-3.3-20.7-1.8-29.2 4.2c-15.4 10.7-31.4 20-48.4 27.4L91.2 446l-6.4-14.7c-10.5 4.6-21.5 8.6-32.7 11.5c.5-.9 1.1-1.8 1.6-2.6C68 416.5 76.3 391 79.4 363.9c1-9.2-2.1-17.7-7.4-23.9c-24.5-29.4-40-62.9-40-100zM256 32C114.5 32 0 125.2 0 240c0 46.6 19.6 87.1 47.4 120.5c0 .1 .1 .1 .1 .2c-2.6 22.6-9.5 43.5-21.3 63.1c-6.5 10.8-13.5 20.8-21.7 29c-4.5 4.6-5.9 11.5-3.4 17.4s8.3 9.8 14.8 9.8c28.7 0 57.6-8.9 81.6-19.3c19.2-8.4 37.1-18.7 53.9-30.5c.2-.1 .5-.2 .7-.1C185.5 441.3 220 448 256 448c141.5 0 256-93.2 256-208S397.5 32 256 32zm0 80c-8.8 0-16 7.2-16 16V256c0 8.8 7.2 16 16 16s16-7.2 16-16V128c0-8.8-7.2-16-16-16zm24 224a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z'/></svg>");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.feedback-triangle {
  background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M34.5 420.4c-1.6 2.8-2.5 6-2.5 9.3c0 10.2 8.2 18.4 18.4 18.4H461.6c10.2 0 18.4-8.2 18.4-18.4c0-3.3-.9-6.4-2.5-9.3L276.5 75.8C272.2 68.5 264.4 64 256 64s-16.2 4.5-20.5 11.8L34.5 420.4zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4c0 27.8-22.6 50.4-50.4 50.4H50.4C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c8.8 0 16 7.2 16 16V304c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16zM232 384a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z'/></svg>");
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.nav-item:hover {
  .money-sack-light {
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%2303b7e5' d='M201.5 144h109l5.3 3.4C370.6 182.5 480 268.3 480 416c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64c0-147.7 109.4-233.5 164.3-268.6l5.3-3.4zm98.4-32H212.1l-6.7-9.8L156.9 32H355.1l-48.4 70.2-6.7 9.8zM152.6 138.5C91.2 183.7 0 273.4 0 416c0 53 43 96 96 96H416c53 0 96-43 96-96c0-142.6-91.2-232.3-152.6-277.5c-9.7-7.1-18.6-13.2-26.4-18.1L351.2 94l38.9-56.4C401 21.7 389.6 0 370.3 0H141.7C122.4 0 111 21.7 121.9 37.6L160.8 94 179 120.4c-7.7 4.9-16.7 11-26.4 18.1zM272 216c0-8.8-7.2-16-16-16s-16 7.2-16 16v17.3c-8.3 1.5-16.7 4.3-24.1 8.7c-13 7.7-23.9 21.1-23.8 40.5c.1 18.4 10.8 30.1 22.7 37.3c10.5 6.3 23.8 10.4 34.8 13.7l0 0 1.6 .5c12.5 3.8 22.2 6.9 29 11.2c5.9 3.7 7.7 7 7.7 11.6c.1 6.6-2.7 10.8-7.8 14c-5.8 3.6-14.5 5.6-23.7 5.3c-11.8-.4-22.7-4.1-36.3-8.7l0 0 0 0 0 0c-2.3-.8-4.7-1.6-7.1-2.4c-8.4-2.8-17.4 1.7-20.2 10.1s1.7 17.4 10.1 20.2c2 .7 4.1 1.4 6.2 2.1l0 0c9.2 3.2 19.7 6.8 30.9 8.9V424c0 8.8 7.2 16 16 16s16-7.2 16-16V406.9c8.7-1.4 17.4-4.3 25.1-9.1c13.3-8.3 23.2-22.2 22.9-41.6c-.2-18.5-10.6-30.6-22.7-38.2c-11-6.9-25-11.2-36.3-14.6l0 0 0 0-.5-.1c-12.6-3.8-22.3-6.8-29.2-10.9c-6-3.6-7.2-6.4-7.3-10.1c0-5.4 2.4-9.4 8.1-12.8c6.2-3.7 15.1-5.7 23.6-5.5c10.1 .2 21 2.3 32.1 5.3c8.5 2.3 17.3-2.8 19.6-11.3s-2.8-17.3-11.3-19.6c-7.5-2-15.6-3.9-24.1-5.1V216z'/></svg>");
  }  
  .feedback {
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%2303b7e5' d='M32 240c0-91.4 93.8-176 224-176s224 84.6 224 176s-93.8 176-224 176c-32 0-63-5.9-93.5-16.2c-9.9-3.3-20.7-1.8-29.2 4.2c-15.4 10.7-31.4 20-48.4 27.4L91.2 446l-6.4-14.7c-10.5 4.6-21.5 8.6-32.7 11.5c.5-.9 1.1-1.8 1.6-2.6C68 416.5 76.3 391 79.4 363.9c1-9.2-2.1-17.7-7.4-23.9c-24.5-29.4-40-62.9-40-100zM256 32C114.5 32 0 125.2 0 240c0 46.6 19.6 87.1 47.4 120.5c0 .1 .1 .1 .1 .2c-2.6 22.6-9.5 43.5-21.3 63.1c-6.5 10.8-13.5 20.8-21.7 29c-4.5 4.6-5.9 11.5-3.4 17.4s8.3 9.8 14.8 9.8c28.7 0 57.6-8.9 81.6-19.3c19.2-8.4 37.1-18.7 53.9-30.5c.2-.1 .5-.2 .7-.1C185.5 441.3 220 448 256 448c141.5 0 256-93.2 256-208S397.5 32 256 32zm0 80c-8.8 0-16 7.2-16 16V256c0 8.8 7.2 16 16 16s16-7.2 16-16V128c0-8.8-7.2-16-16-16zm24 224a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z'/></svg>");
  }
  .feedback-triangle {
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%2303b7e5' d='M34.5 420.4c-1.6 2.8-2.5 6-2.5 9.3c0 10.2 8.2 18.4 18.4 18.4H461.6c10.2 0 18.4-8.2 18.4-18.4c0-3.3-.9-6.4-2.5-9.3L276.5 75.8C272.2 68.5 264.4 64 256 64s-16.2 4.5-20.5 11.8L34.5 420.4zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4c0 27.8-22.6 50.4-50.4 50.4H50.4C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c8.8 0 16 7.2 16 16V304c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16zM232 384a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z'/></svg>");
  }  
}