// Note: these are mostly obsolete now with Bootstrap 4 equivalents
// https://getbootstrap.com/docs/4.0/utilities/spacing/
.margin-left-4 {
  margin-left: 4px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-left-8 {
  margin-left: 8px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-left-40 {
  margin-left: 40px;
}

.margin-right-4 {
  margin-right: 4px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-right-6 {
  margin-right: 6px;
}
.margin-right-8 {
  margin-right: 8px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-right-40 {
  margin-right: 40px;
}

.margin-top-minus-20 {
  margin-top: -20px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-top-200 {
  margin-top: 200px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-vertical-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.margin-vertical-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-vertical-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.margin-vertical-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.padding-right-0 {
  padding-right: 0;
}
.padding-right-5 {
  padding-right: 5px;
}

.padding-left-0 {
  padding-left: 0;
}
.padding-left-5 {
  padding-left: 5px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-top-10 {
  padding-top: 10px;
}
.padding-top-20 {
  padding-top: 20px;
}

.padding-horizontal-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-vertical-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.no-padding {
  padding: 0;
}
.no-margin {
  margin: 0;
}
.no-margin-bottom {
  margin-bottom: 0;
}

/* new, bootstrap extensions (kinda) */
.mr-1-between {
  div,
  button {
    margin-right: 1rem;
  }
}

@media (max-width: 47.999rem) {
  .mobile-mt-1 {
    margin-top: 1rem;
  }
  .mobile-mt-2 {
    margin-top: 2rem;
  }
  .mobile-mt-3 {
    margin-top: 3rem;
  }
}