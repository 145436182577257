.leaderboard {
  font-size: 0.75rem;
  .leaderboard-header {
    font-weight: bold;
  }
  .col-2, .col-one-point-five {
    padding-left: 12px;
    padding-right: 5px;
    &.no-padding {
      padding-left: 0;
      padding-right: 0;
    }
    &.no-padding-right {
      padding-right: 0;
    }
  }
  .col-one-point-five {
    width: 14% !important;
    flex: 0 0 14% !important;
    max-width: 14% !important;
  }
  .client-col {
    width: 30% !important;
    flex: 0 0 30% !important;
    max-width: 30% !important;
    padding-right: 0;
  }
}
