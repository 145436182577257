.popover {
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.15), 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  .popover-body {
    text-align: center;
    padding: 1rem 0.75rem;
    color: $base-color;
    line-height: 1.125rem;
    font-family: Montserrat, 'Open Sans'
  }
}
