input,
select {
  font-size: 100%;
}
input {
  border-color: $input-border-color !important;
  color: $gray-800;
  &:focus {
    border-color: $input-border-color-focused !important;
  }
}
.large-input-form,
.alcohol-calculator {
  input {
    font-size: 1.5rem;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .input-icon-container {
    input {
      padding-left: 1.2em;
    }
    em {
      position: absolute;
      top: 0.7rem;
    }
  }
}
.alcohol-calculator {
  input {
    border-width: 1px;
    padding: 2rem 1rem;
    width: 10rem;
  }
  .rc-slider,
  .slider-container {
    max-width: 20rem;
  }
  .default-container {
    max-width: 20rem;
  }
}
.inline-form {
  label {
    font-size: 0.9rem;
    &.caption {
      font-size: 0.75rem;
    }
  }
  .stacked > label {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }
  label.bodycopy {
    font-size: 0.875rem;
  }
}
.form-control {
  padding-left: 0.65rem;
}
.required-field {
  color: red;
}
.checkbox-row {
	position: relative;
	display: flex;
	align-items: center;
  
	input[type="checkbox"] {
	  position: relative;
	  z-index: 1; // Ensure the checkbox is above the pseudo-element
	}
	input[type="checkbox"]::before {
		content: '';
		position: absolute;
		top: -25px; // Adjust as needed
		left: -15px; // Adjust as needed
		right: -40px; // Adjust as needed
		bottom: -25px; // Adjust as needed
		z-index: 0; // Ensure the pseudo-element is below the checkbox
	}
  .form-group,
  .col-form-label {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .col-form-label {
    padding-bottom: 7px;
  }
  button.card-tool {
    border: 0;
    background: none;
  }
}
.c-checkbox input[type='checkbox']:checked + span,
.c-checkbox input[type='radio']:checked + span,
.c-radio input[type='checkbox']:checked + span,
.c-radio input[type='radio']:checked + span {
  background-color: $blue-100;
  border-color: $blue-100;
}
.c-checkbox input[type='checkbox']:checked + span:before,
.c-checkbox input[type='radio']:checked + span:before,
.c-radio input[type='checkbox']:checked + span:before,
.c-radio input[type='radio']:checked + span:before {
  color: $button-text-color;
}
.upload-field {
  button,
  button:active,
  button:hover,
  button:focus {
    border: 0;
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 0;
  }
  .user-avatar-photo {
    width: 6.75rem;
    height: 6.75rem;
    border-radius: 6.75rem;
    overflow: hidden;
    img {
      // width: 4.75rem;
      width: 6.75rem;
      height: auto;
    }
  }
  .user-avatar-initials {
    height: 6.75rem;
    width: 6.75rem;
    border-radius: 4rem;
    line-height: 6.75rem;
    font-size: 2rem;
  }
  .team-logo-upload {
    height: 6.75rem;
  }
}
.stripe-element-container {
  // border: 1px solid $border-color;
  // padding: 0.75rem 0.5rem;
  &:focus {
    border-color: $input-border-color-focused !important;
  }
  height: 2.9rem;
}
.optional-field-connector {
  width: 1rem;
  height: 2.6rem;
  border-bottom: 1px solid $base-color;
  border-left: 1px solid $base-color;
  margin-left: 1rem;
  margin-right: 0.5rem;
}
.input-caption {
  font-size: 0.65rem;
  margin-top: 0.35rem;
  color: $gray-400;
}
.form-group {
  .form-group {
    margin-bottom: 0;
    flex: 1;
  }
}
.range-container {
  .caption {
    font-size: 0.75rem;
    width: 3rem;
    text-align: center;
  }
  align-items: center;
  justify-content: space-between;
}
.macro-targets-form,
.weigh-ins-form {
  .form-group {
    margin-bottom: 0;
    input {
      text-align: center;
    }
  }
  .macro-value {
    div {
      line-height: 21px;
    }
  }
}
.weigh-ins-form {
  margin: 0;
  padding: 0;
  .datetime-container {
    input {
      text-align: left;
    }
  }
}
.emoji-wrapper em {
  font-size: 4rem;
  &.checked {
    color: $blue-300;
  }
  &.unchecked {
    color: $gray-400;
  }
}
.radio-label-container {
  // text-align: center;
}
.toggle-button {
  border: 1px solid $gray-300;
  height: 3.5rem;
  width: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  font-size: 0.85rem;
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  background-color: $gray-100;
  &.checked {
    background-color: $blue-200;
    border-color: $blue-300;
    box-shadow: none;
  }
  &.unchecked {
    // background-color: $gray-200;
  }
}
label.full-size {
  font-size: 0.9rem !important;
}
.day-type-container {
  div {
    align-items: center;
    &.caption {
      line-height: 18px;
    }
  }
}
.rb-list-wrapper {
  em {
    margin-right: 0.5rem;
    font-size: 1.25rem;

    &.checked::before {
      // content: "\f058";
      color: $blue-400;
    }
  }
}
.label-left-container {
  .radio-label-container {
    text-align: left;
  }
}
.css-efmrnd-menu {
  z-index: 20;
}
.action-row {
  .form-group {
    margin-bottom: 0;
    .fa, .checkbox {
      margin: 0;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield; /* Firefox */
    }
  }
}
input.no-spin::-webkit-outer-spin-button,
input.no-spin::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0;
}

input.no-spin[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.pagination-size {
  .react-select__control {
    border: 0;
    min-height: auto !important;
    cursor: pointer;
    width: 3rem !important;
    background-color: transparent;
    .react-select__value-container, .react-select__value-container--has-value {
      padding-right: 1rem;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .react-select__indicators {
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__dropdown-indicator  {
        padding: 0;
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
      font-size: .75rem;
    }
    .react-select__menu {
      width: 300px;
    }
  }
}
.dashboard-pagination {
  .react-select__option {
    color: $base-color;
  }
}
.color-picker-container {
  position: relative;
  overflow: visible;
  .selected-color {
    width: 5rem;
    text-align: center;
  }
  .swatch-container {
    width: 5rem;
    height: 2rem;
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
    .swatch {
      width: 100%;
      height: 100%;
    }
  }
  .popover {
    position: absolute;
    z-index: 2;
    top: -240px;
  }
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
