.nav-tabs {
  // box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.1), 0px 3px 3px -4px rgba(0, 0, 0, 0.15);
}
.nav-tabs > .nav-item > .nav-link {
  background-color: #fff;
  border: none;
  color: $button-text-color;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  &.active,
  &:active,
  &:focus,
  &:hover {
    color: $blue-300;
    //border-bottom: 3px solid $blue-300 !important;
    font-weight: bold;
  }
  em {
    font-size: 1.1rem;
    margin-right: 0.35rem;
  }
}
.nav-tabs > .nav-item {
  position: relative;
  .tab-indicator {
    display: none;
  }
  &.active {
    .nav-link {
      color: $blue-300;
      font-weight: bold;
    }
    .tab-indicator {
      display: block;
      transform: translateX(0px);
      width: 100%;
      left: 0;
      overflow: hidden;
      transition: transform 436ms cubic-bezier(0.4, 0, 0.2, 1);
      background: $blue-300;
      border-radius: 4px 4px 0 0;
      height: 4px;
      position: absolute;
      bottom: 0;
      overflow: hidden;
      transition: transform 436ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .tab-indicator::after {
      border-radius: 4px 4px 0 0;
      height: 4px;
      width: 100%;
      content: '';
    }
  }
}
.tab-content {
  border: 0;
  margin-top: 1rem;
  padding: 10px 0;
}
.side-nav {
  border-right: 1px solid $border-color;
  box-shadow: 3px 0px 3px -2px rgba(0, 0, 0, 0.1), 3px 0px 3px -4px rgba(0, 0, 0, 0.15);
  margin-top: -26px;
  padding-top: 25px;
  // background-color: $purple-100;
  padding-left: 8px;
  .span-nav {
    margin-bottom: 1rem;
    cursor: pointer;
    &.active {
      color: $blue-300;
      font-weight: bold;
    }
  }
}
.sub-nav-tabs {
  background-color: $gray-100;
  .nav-link {
    background-color: $gray-100 !important;
    font-size: 0.75rem;
  }
}
.tab-content.no-top-margin {
  margin-top: 0;
  padding-top: 0;
}
.sub-nav-tabs > .nav-item.active .tab-indicator {
  height: 2.5px !important;

}
.add-clients {
  .btn-group-toggle {
    height: 2.5rem;
    background-color: $gray-200;
    border-radius: 300px;
    margin-bottom: 0.4rem;
    .btn {
      font-size: 0.75rem;
      line-height: 1.6rem;
      background-color: $gray-200;
      border-radius: 300px;
      // border: 2px solid $white;
      color: $gray-600;
      box-shadow: none;
      min-width: 165px;
      &:hover {
        z-index: 0;
      }
      &:active {
        box-shadow: none;
        background-color: $gray-200;
        // border: none;
        color: $gray-600;
        border-color: $gray-200;
        border-radius: 300px;
      }
      &.active {
        background-color: $blue-200;
        // background-color: $purple-900;
        // border: 2px solid $white;
        box-sizing: border-box;
        // color: #000;
        color: $button-text-color !important;
        transition: all 0.2s ease-out;
        box-shadow: none;
        color: $white;
        border-radius: 300px;
      }
    }
  }
}