.dropdown-label {
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}

.dropdown-with-search {
  width: '100%';
  min-height: 38px;
  border: 1px solid #CFCFCF;
  border-radius: 0.25rem;
  position: relative;
  margin-top: 0.25rem;

  &:hover {
    border-color: #6c757d;
  }

  .dropdown-placeholder {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.selected {
      color: black; // Cambia el color a negro si también tiene la clase .selected
    }
  }

	.dropdown-header {
	  padding: 10px;
	  display: flex;
	  min-height: 38px;
	  padding-left: 0.65rem;
	  font-size: 0.875rem;
	  justify-content: space-between;
    align-items: center;
	  cursor: default;
	}

  .dropdown-body {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    top: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    z-index: 1;
    position: absolute;
    background-color: white;
    border: 1px solid #CFCFCF;
    border-radius: 0.25rem;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .search-input-container {
      position: relative;
      width: 100%;
      margin-bottom: 8px;
      padding-left: 8px;
      padding-right: 8px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.25);

      .search-icon {
        position: absolute;
        left: 20px;
        top: 30%;
        transform: translateY(-30%);
        pointer-events: none;
        color: #6c757d;
      }

      .search-input {
        width: 100%;
        min-height: 38px;
        font-size: 0.875rem;
        padding: 8px;
        padding-left: 2rem;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border: 1px solid #CFCFCF;
        border-radius: 0.25rem;
      }
    }

	  .dropdown-list {
      list-style: none;
      margin-top: 10px;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
      max-height: 200px;
      overflow-y: auto;
    
      .dropdown-list-item {
        padding: 5px 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    
        .item-text {
          width: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
      
          input[type="checkbox"] {
            appearance: none;
            width: 18px;
            height: 18px;
            border: 1px solid #ccc;
            border-radius: 2px;
            position: relative;
            margin-right: 8px;
            cursor: pointer;
            padding: 0.1rem;
    
            &:checked {
              background-color: #03b7e5;
              border-color: #03b7e5;
    
              &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNzUgOEw2IDExLjI1TDE0LjI1IDMuNzUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }

        .extra-item-label {
          color: #fff;
          padding: 0.25rem;
          background-color: #CFCFCF;
          border-radius: 18px;
          font-size: 0.6rem;
        }
      }
	  }
  }
}
  