.dropbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 180px;
  width: 180px;
  padding: 40px;
  border-width: 2px;
  border-radius: 90px;
  border-style: dashed;
  background-color: #fafafa;
  color: #000;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
  background-size: cover;
  background-position: center;
}



