$menu-width: 40vw;
$transition-duration: 0.5s;
$background-color: #fff;
$box-shadow-color: rgba(0, 0, 0, 0.3);
$overlay-color: rgba(0, 0, 0, 0.5);
$button-color: #03b7e5;
$button-disabled-color: #1B201B1F;
$button-hover-color: #03476e;
$header-bg-color: #f8f9fa;
$border-color: #ddd;
$font-size-large: 1.5rem;

.modal-dialog {
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1), 0px 2px 6px -1px rgba(0, 0, 0, 0.15);
  img {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 60rem;
  }
  .modal-medium {
    max-width: 40rem;
  }
  .modal-small {
    max-width: 30rem;
	align-self: center;

	.modal-content {
		padding: 1.5rem 1.5rem;
		height: 40vh; // 50% of the viewport height
		max-height: 30rem;
	}
  }
}
.modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}
.modal-content {
  padding: 1.5rem 1.5rem;
  height: 90vh; // 50% of the viewport height
  max-height: 50rem;
}
.modal-body {
	overflow-y: auto;
}
.callout-container {
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
  background-color: $gray-200;
  border-radius: 1rem;
  padding: 1rem;
}
.onboarding-modal {
  .modal-content {
    padding-top: 0;
  }
  .modal-header {
    padding: 0.5rem 0 1.5rem;
    margin: 0 -2.5rem;
    font-size: 0.9rem;
    border-bottom: 1px solid $border-color;
    .col-3 {
      text-align: center;
      &.active {
        font-weight: bold;
      }
    }
  }
  .content {
    padding-top: 1.5rem;
    p {
      margin-left: 10rem;
      margin-right: 10rem;
    }
  }
}
.error-button-modal {
	padding: 10px 20px;
	margin-right: 10px;
	margin-left: 10px;
	background-color: #B11111;
	color: $background-color;
	border: none;
	border-radius: 5px;
	cursor: pointer;
  
	&:hover {
	  background-color: #560202;
	}
}

.cancel-button-modal {
	padding: 10px 20px;
	margin-right: 10px;
	margin-left: 10px;
	background-color: #fff;
	color: #000;
	border-radius: 5px;
	border: 1px solid #DBDDDC;
	cursor: pointer;
  
	&:hover {
	  background-color: #DBDDDC;
	}
  }
  
