.alert-bar {
  background-color: #FF7C74;
  // background-color: $gold-500;
  padding: 1rem;
  text-align: center;
  height: 4rem;
  border-bottom: 1px solid $gold-600;
  &.gray-100 {
    border-bottom: 1px solid $gray-200;
  }
  &.gray-200 {
    border-bottom: 1px solid $gray-300;
  }
  &.info {
    background-color: #8180cd;
    color: $white;
    // background-color: $white;
  }
  &.marketing {
    background-color: #FFBD00;
  }
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .dismiss {
    font-size: 1.25rem;
    position: absolute;
    right: 2rem;
    top: 1.35rem;
    margin: 0;
    cursor: pointer;
  }
}
